import IContentfulIfuVersionInfo from '../../interfaces/ContentfulIfuVersionInfo';
import IContentfulProduct from '../../interfaces/ContentfulProduct';
import ContentfulIfuVersionInfo from './ifu-version-info';

export default class ContentfulProduct {
  name: string;
  slug: string;
  ifuCode: string;
  ifuVersions: ContentfulIfuVersionInfo[];

  constructor(item: IContentfulProduct) {
    this.name = item.name || '';
    this.slug = item.slug || '';
    this.ifuCode = item.ifuCode || '';
    this.ifuVersions =
      item.versions && item.versions.length
        ? item.versions.map((versionInfo: IContentfulIfuVersionInfo) => new ContentfulIfuVersionInfo(versionInfo))
        : [];
  }
}
