import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import IContentfulIfuVersionInfo from '../../interfaces/ContentfulIfuVersionInfo';
import ContentfulIfuVersionInfo from '../../models/contentful/ifu-version-info';
import Languages from '../ifu-languages/language-list-overlay';
import MailInAddressModal from '../mail-in-address-modal/mail-in-address-modal';
import MoreInfoOverlay from '../more-info-overlay/more-info-overlay';
import styles from './ifu-prev-version-accordion.module.scss';
import AnalyticsService from '../../services/analytics';

interface IContentfulIfuPrevVersionList {
  prevVersions: IContentfulIfuVersionInfo[];
  ifuCode: string;
}

interface IState {
  activeKey: string;
  isOpenAddressForm: boolean;
  selectedRevisionIndex: number;
}

class IfuPreviousVersionAccordion extends React.Component<IContentfulIfuPrevVersionList, IState> {
  Analytics: null | AnalyticsService = null;

  constructor(props: IContentfulIfuPrevVersionList) {
    super(props);

    this.state = {
      activeKey: '0',
      isOpenAddressForm: false,
      selectedRevisionIndex: 0,
    };
  }

  componentDidMount() {
    this.Analytics = new AnalyticsService();
  }

  onClick(activeKey: string) {
    this.setState({ activeKey: activeKey === '0' ? '1' : '0' });
  }

  openInstructions(selectedIfuVersion: IContentfulIfuVersionInfo) {
    this.Analytics!.trackViewInstrictions(this.props.ifuCode, selectedIfuVersion, 'Previous');
  }

  openAddressForm(selectedRevisionIndex: number) {
    this.setState({ isOpenAddressForm: true });
    this.setState({ selectedRevisionIndex });
    this.Analytics!.trackRequestsByMail(this.props.ifuCode, this.props.prevVersions[selectedRevisionIndex], 'Previous');
  }

  closeModal = () => this.setState({ isOpenAddressForm: false });

  render() {
    if (!this.props.prevVersions || this.props.prevVersions.length === 0) {
      return null;
    }

    return (
      <section className="container px-0 px-md-3 border-light-gray border-top border-bottom ">
        <Accordion activeKey={this.state.activeKey}>
          <Card className="border-0" key="1">
            <button
              type="button"
              onClick={this.onClick.bind(this, this.state.activeKey)}
              className="btn-plain bg-none border-0 border-top-0 text-body w-100 
                text-left p-3 px-md-0 py-md-1">
              <div className="d-flex align-items-center p-md-3">
                {this.state.activeKey === '0' ? (
                  <i className="fas fa-chevron-right text-primary large" />
                ) : (
                  <i className="fas fa-chevron-down text-primary large" />
                )}
                <div className="pl-2 text-dark">Previous Revisions</div>
              </div>
            </button>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="pb-4">
                  These are former versions of the Glidewell IFUs. The IFUs in this section are superseded by the new
                  version available above.
                </div>
                {this.props.prevVersions.map((ifuVersion: ContentfulIfuVersionInfo, index: number) => (
                  <div className={`${styles.accordionBody} pt-3`} key={ifuVersion.revision}>
                    <div className="mb-0 d-flex justify-content-between">
                      <div>
                        <a
                          className="border-0 link-plain text-primary"
                          href={ifuVersion.ifuDocument.file.url}
                          target="_blank"
                          onClick={this.openInstructions.bind(this, ifuVersion)}>
                          Rev {ifuVersion.revision} {ifuVersion.lastUpdatedDate}
                        </a>
                        <div className="d-flex pb-3 small pt-1">
                          <p className="mb-0">
                            {ifuVersion.ifuDocument && ifuVersion.ifuDocument.file.contentType === 'application/pdf'
                              ? 'PDF'
                              : ifuVersion.ifuDocument.file.contentType}
                          </p>
                          <span className="px-2">|</span>
                          <p className="mb-0">
                            {ifuVersion.ifuDocument &&
                              (ifuVersion.ifuDocument.file.details.size / Math.pow(1024, 2)).toFixed(1)}
                            &nbsp; MB
                          </p>
                          <span className="px-2">|</span>
                          <div className="mb-0 d-flex">
                            Translated in {ifuVersion.languages.length} languages &nbsp;
                            <div className="font-size-base line-height-base">
                              <Languages options={ifuVersion.languages} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-md-none">
                        <MoreInfoOverlay options={ifuVersion} ifuCode={this.props.ifuCode} />
                      </div>
                      <div className="d-md-flex d-none">
                        <a
                          className="border-0 link-plain btn border-light-gray bg-none link-plain font-size-sm p-2 text-primary"
                          href={ifuVersion.ifuDocument.file.url}
                          target="_blank"
                          onClick={this.openInstructions.bind(this, ifuVersion)}>
                          View Instructions
                        </a>
                        <div className="pl-4 align-items-center">
                          <button
                            className="btn border-0 bg-none link-plain font-size-sm px-0 py-2 text-primary"
                            onClick={this.openAddressForm.bind(this, index)}>
                            REQUEST BY MAIL
                          </button>
                          <MailInAddressModal
                            isOpen={this.state.isOpenAddressForm}
                            closeModal={this.closeModal}
                            selectedIfuInfo={this.props.prevVersions[this.state.selectedRevisionIndex]}
                            ifuCode={this.props.ifuCode}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>
    );
  }
}

export default IfuPreviousVersionAccordion;
