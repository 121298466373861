import { Link } from 'gatsby';
import React from 'react';
import { FaAngleDoubleLeft } from 'react-icons/fa';

class IFUHomeBtn extends React.Component {
  render() {
    return (
      <div className="container">
        <Link className="link-plain d-flex align-items-center font-size-medium text-primary" to="/">
          <FaAngleDoubleLeft />
          <span className="pl-1">IFU Home</span>
        </Link>
      </div>
    );
  }
}
export default IFUHomeBtn;
