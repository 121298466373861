import IContentfulDocument, { IContentfulFile } from '../../interfaces/ContentfulDocument';
import ContentfulFile from './ifu-file';

export default class ContentfulDocument {
  file: IContentfulFile;

  constructor(item: IContentfulDocument) {
    this.file = (item.file && new ContentfulFile(item.file)) || null;
  }
}
