import IContentfulDocument from '../../interfaces/ContentfulDocument';
import IContentfulIfuVersionInfo from '../../interfaces/ContentfulIfuVersionInfo';
import ContentfulDocument from './ifu-document';

export default class ContentfulIfuVersionInfo {
  name: string;
  revision: number;
  ifuDocument: IContentfulDocument;
  lastUpdatedDate: Date;
  languages: string[];

  constructor(item: IContentfulIfuVersionInfo) {
    this.name = (item.name && item.name) || '';
    this.revision = item.revision || 0;
    this.ifuDocument = item.ifuDocument && new ContentfulDocument(item.ifuDocument);
    this.lastUpdatedDate = item.lastUpdatedDate || null;
    this.languages = item.languages;
  }
}
