import { IContentfulFileDetails } from '../../interfaces/ContentfulDocument';

class ContentfulFileDetails {
  size: number;

  constructor(item: IContentfulFileDetails) {
    this.size = item.size || 0;
  }
}

export default ContentfulFileDetails;
