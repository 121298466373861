import { IContentfulFile, IContentfulFileDetails } from '../../interfaces/ContentfulDocument';
import ContentfulFileDetails from './ifu-file-details';

export default class ContentfulFile {
  contentType: string;
  details: IContentfulFileDetails;
  url: string;

  constructor(item: IContentfulFile) {
    this.details = (item.details && new ContentfulFileDetails(item.details)) || null;
    this.contentType = item.contentType || '';
    this.url = item.url || '';
  }
}
