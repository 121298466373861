import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
// tslint:disable-next-line: no-submodule-imports
import ModalHeader from 'react-bootstrap/ModalHeader';
import IContentfulIfuVersionInfo from '../../interfaces/ContentfulIfuVersionInfo';
import styles from './mail-in-address-modal.module.scss';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedIfuInfo: IContentfulIfuVersionInfo;
  ifuCode: string;
}

class MailInAddressModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  onHide(): void {
    this.props.closeModal();
  }

  render() {
    return (
      <Modal
        dialogClassName="address-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        show={this.props.isOpen}
        onHide={
          // tslint:disable-next-line: jsx-no-bind
          this.onHide.bind(this)
        }
        backdropClassName={styles.backdrop}>
        <ModalHeader closeButton={true} className="border-0 pb-0" />
        <ModalBody className="p-0">
          <div className={styles.iframeWrap}>
            <iframe
              id="JotFormIFrame-193425501966159"
              title="Mailing Address Update Form"
              src={
                'https://lab.jotform.com/193425501966159?product=' +
                this.props.selectedIfuInfo.name +
                '&rev=' +
                this.props.selectedIfuInfo.revision +
                '&date=' +
                this.props.selectedIfuInfo.lastUpdatedDate +
                '&ifucode=' +
                this.props.ifuCode
              }
              className={styles.iframe}
              scrolling="yes"
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default MailInAddressModal;
