import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import IFUHomeBtn from '../components/ifu-home-btn/ifu-home-btn';
import Languages from '../components/ifu-languages/language-list-overlay';
import IfuPreviousVersionAccordion from '../components/ifu-prev-versions/ifu-prev-version-accordion';
import MailInAddressModal from '../components/mail-in-address-modal/mail-in-address-modal';
import IContentfulProduct from '../interfaces/ContentfulProduct';
import ContentfulIfuVersionInfo from '../models/contentful/ifu-version-info';
import ContentfulProduct from '../models/contentful/product';
import AnalyticsService from '../services/analytics';

export default (info: { data: { contentfulProduct: IContentfulProduct } }) => {
  const productInfo = new ContentfulProduct(info.data.contentfulProduct);
  const sortedArray = productInfo.ifuVersions.sort((item1, item2) => item2.revision - item1.revision);
  const latestVersion: ContentfulIfuVersionInfo = sortedArray[0];
  const prevVersions: ContentfulIfuVersionInfo[] = sortedArray.slice(1);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [Analytics, setAnalytics] = useState();

  useEffect(() => {
    setAnalytics(new AnalyticsService());
  }, []);

  const openAddressForm = () => {
    setisOpen(true);
    Analytics.trackRequestsByMail(productInfo.ifuCode, latestVersion, 'Latest');
  };

  const onClickViewInstructions = () => {
    Analytics.trackViewInstrictions(productInfo.ifuCode, latestVersion, 'Latest')
  }

  const closeModal = () => setisOpen(false);

  if (!productInfo || !productInfo.ifuVersions || !productInfo.ifuVersions.length) {
    return null;
  }

  return (
    <>
      <IFUHomeBtn />
      <div className="container pt-5 pb-9">
        <h3>{productInfo.name}</h3>
        <table className="table table-borderless col-md-4 mb-0">
          <tbody>
            <tr>
              <td className="p-0">IFU</td>
              <td className="pl-3 p-0 text-dark">{productInfo.ifuCode}</td>
            </tr>
            <tr>
              <td className="p-0">Rev</td>
              <td className="pl-3 p-0 text-dark">
                {latestVersion.revision} &nbsp; {latestVersion.lastUpdatedDate} &nbsp;
                <span className="border border-secondary text-secondary border-rounded font-weight-medium px-1">
                  <i className="fa fa-check" />
                  <span className="font-size-medium pl-1">LATEST</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex py-3">
          <p className="mb-0">
            {latestVersion.ifuDocument && latestVersion.ifuDocument.file.contentType === 'application/pdf'
              ? 'PDF'
              : latestVersion.ifuDocument.file.contentType}
          </p>
          <span className="px-2">|</span>
          <p className="mb-0">
            {latestVersion.ifuDocument && (latestVersion.ifuDocument.file.details.size / Math.pow(1024, 2)).toFixed(1)}
            &nbsp; MB
          </p>
          <span className="px-2">|</span>
          <div className="mb-0 d-flex">
            {latestVersion.languages.length} Languages&nbsp;
            <Languages options={latestVersion.languages} />
          </div>
        </div>
        <div className="d-flex">
          <a
            className="border-0 link-plain"
            href={latestVersion.ifuDocument.file.url}
            target="_blank"
            onClick={onClickViewInstructions}>
            <button className="btn btn-ifu">View Instructions</button>
          </a>
          <div className="pl-3 align-items-center">
            <button
              className="btn border-0 bg-none link-plain font-size-sm px-0 text-primary"
              onClick={openAddressForm}>
              REQUEST BY MAIL
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3 ">
        <IfuPreviousVersionAccordion prevVersions={prevVersions} ifuCode={productInfo.ifuCode} />
      </div>
      <MailInAddressModal
        isOpen={isOpen}
        closeModal={closeModal}
        selectedIfuInfo={latestVersion}
        ifuCode={productInfo.ifuCode}
      />
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      name
      ifuCode
      versions {
        name
        languages
        revision
        lastUpdatedDate(formatString: "YYYY/MM/DD")
        ifuDocument {
          file {
            details {
              size
            }
            contentType
            url
          }
        }
      }
    }
  }
`;
