import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import ContentfulIfuVersionInfo from '../../models/contentful/ifu-version-info';
import AnalyticsService from '../../services/analytics';
import MailInAddressModal from '../mail-in-address-modal/mail-in-address-modal';

const MoreInfoOverlay = ({ options, ifuCode }: { options: ContentfulIfuVersionInfo; ifuCode: string }) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [show, setShow] = useState<boolean>(false);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [Analytics, setAnalytics] = useState();

  const ellipsis = `<use xlink:href="#ellipsis-icon" />`;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    setAnalytics(new AnalyticsService());
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      setShow(false);
    }
  };

  const handleClick = () => {
    setShow(false);
  };

  const openAddressForm = () => {
    setisOpen(true);
    Analytics.trackRequestsByMail(ifuCode, options, 'Previous');
  };

  const closeModal = () => {
    setisOpen(false);
  };

  return (
    <>
      <div ref={ref} className="pl-1">
        <div
          className="ellipsis-icon"
          onClick={
            // tslint:disable-next-line: jsx-no-lambda
            () => setShow(!show)
          }>
          <svg dangerouslySetInnerHTML={{ __html: ellipsis }} />
        </div>

        <Overlay target={ref.current} show={show} placement="left-start">
          <div className="overlay font-size-base">
            <button className="list-group bg-none border-0" onClick={openAddressForm}>
              Request by mail
            </button>
            <button
              className="list-group mt-3 bg-none border-0"
              onClick={
                // tslint:disable-next-line: jsx-no-lambda
                () => handleClick()
              }>
              Cancel
            </button>
          </div>
        </Overlay>
      </div>
      <MailInAddressModal isOpen={isOpen} closeModal={closeModal} selectedIfuInfo={options} ifuCode={ifuCode} />
    </>
  );
};

export default MoreInfoOverlay;
