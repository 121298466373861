import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { TiMediaRecord } from 'react-icons/ti';
import styles from './language-list-overlay.module.scss';

const Languages = ({ options }: { options: string[] }) => {
  const target = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [show, setShow] = useState<boolean | undefined>(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (target.current && !target.current.contains(event.target as HTMLElement)) {
      setShow(false);
    }
  };

  const showLanguages = () => {
    setShow(!show);
  };

  return (
    <div ref={target}>
      <div onClick={showLanguages} className="cursor-pointer">
        <IoMdInformationCircleOutline />
      </div>

      <Overlay target={target.current} show={show} placement="bottom">
        <div className={`${styles.langListBlock} font-size-medium`}>
          <div className="overlay">
            <b>Languages contained in the instructions include:</b>
            <div className="list-group d-flex flex-row flex-wrap pt-2">
              {options.map((value: string, index: number) => (
                <div
                  className="list-group-item w-50 list-group-item-action pt-1 p-0 d-flex align-items-center"
                  key={index}>
                  <TiMediaRecord className="icon" />
                  <span className="pl-1">{value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default Languages;
